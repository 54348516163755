<template>
    <div class="fixture mb-2 rounded-2">
        <h4>
            {{ name }}
            <!-- <span class="download" @click="downloadFixture(filename)" href="#">&#129095;</span> -->
        </h4>
        <div class="row">
            <div class="col-md-2 overflow-hidden" v-for="channel in channels.sort((a, b) => (a.Number > b.Number) ? 1 : -1)" :title="channel.Attribute">
                {{ channel.Number }}&nbsp;-&nbsp;{{ channel.Attribute.replace(' ', '&nbsp;') }}
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    name: 'Fixture',
    props: {
        name: String,
        filename: String,
        channels: Array
    },
    methods: {
         download(path, filename) {
            const anchor = document.createElement('a');
            anchor.href = path;
            anchor.download = filename;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        },
        async downloadFixture(fixtureFilename) {
            try {
                const data = await fetch(`https://111821.selcdn.ru/mafixture/${fixtureFilename}`);
                const blob = new Blob([data], { type: 'application/json'});
                const url = URL.createObjectURL(blob);
                download(url, fixtureFilename);
                URL.revokeObjectURL(url);
            } catch {
                alert("download error");
                console.error("download error");
            }
        }
    }
}
</script>

<style scoped>
.fixture {
    border: #ffb300 1px  solid;
    padding: 5px;
    font-size: 10px;
}

h4 {
    color: #fff;
}

.download {
    color: #ffb300;
    text-decoration: none;
    cursor: pointer;
}

.download:hover {
    color: #000;
}

</style>