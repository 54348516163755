<template>

<div class="input-group input-group-lg mb-4">
    <button class="btn btn-outline-secondary" type="button" @click="removeChannel">-</button>
    <input type="number" class="form-control" v-model="channelsCount" @change="checkChannelsCount">
    <button class="btn btn-outline-secondary" type="button" @click="addChannel">+</button>
</div>

<div v-for="chIndex in channelsCount">
    <div class="input-group mb-3">
        <span class="input-group-text">{{ chIndex }}</span>
        <select class="form-select" :channel="chIndex" @change="changeAttribute">
            <option v-for="(item, key) in attributes" :value="key">{{ item }}</option>
        </select>
    </div>
</div>
</template>
<script>

export default {
    name: 'FilterPanel',
    data() {
        return {
            channelsCount: 0,
            choosenAttributes: [],
            attributes: {
                "ANY": "ANY",
                "DIM": "Dimmer",
                "SHUTTER": "Shutter/Strobe",
                "COLORRGB1": "Red",
                "COLORRGB2": "Green",
                "COLORRGB3": "Blue",
                "COLORRGB4": "Amber",
                "COLORRGB5": "White",
                "COLORRGB15": "UV",
                "COLOR1": "Color Wheel",
                "CTO": "CTO",
                "CTB": "CTB",
                "EFFECTMACRO": "Macro",
                "PAN": "Pan",
                "PAN (fine)": "Pan (fine)",
                "TILT": "Tilt",
                "TILT (fine)": "Tilt (fine)",
                "GOBO1": "Gobo1",
                "GOBO1_POS": "Gobo1 <>",
                "GOBO2": "Gobo2",
                "GOBO2_POS": "Gobo2 <>",
                "EFFECTWHEEL": "Effect",
                "EFFECTINDEXROTATE": "Effect <>",
                "FROST": "Frost",
                "ZOOM": "Zoom",
                "FOCUS": "Focus",
                "CONTROLRANGE": "Reset",
                "POSITIONMSPEED": "PT Speed",
                "DIM (fine)": "Dimmer (fine)",
                "DIM (ultra)": "Dimmer (ultra)",
                "PAN (ultra)": "Pan (ultra)",
                "TILT (ultra)": "Tilt (ultra)",
            }
        }  
    },
    methods: {
        addChannel() {
            if (this.channelsCount >= 512) return;
            this.channelsCount++;
            this.$emit("changeChCount", this.channelsCount);
        },
        removeChannel() {
            if (this.channelsCount <= 1) return;
            this.channelsCount--;
            this.$emit("changeChCount", this.channelsCount);
        },
        checkChannelsCount() {
            if (this.channelsCount > 512 || this.channelsCount < 1) {
                this.channelsCount = 1;
            }
            this.$emit("changeChCount", this.channelsCount);
        },
        changeAttribute(e) {
            this.choosenAttributes[e.target.getAttribute('channel')] = e.target.value;
            this.$emit("changeAttributes", this.choosenAttributes);
        }
    }
}
</script>

<style scoped>

</style>