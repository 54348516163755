<template>
    <div class="text-muted small mt-5 footer">
        All rights to the trademarks used belong to their respective owners.<br>
        Created by <a href="https://sysolyatin.com">Artem Sysolyatin</a> in Russia<br><br>
        Application version: {{ appVersion }}
    </div>
</template>
<script>
import {version} from '../../package.json'

export default {
    name: 'Footer',
    data() {
        return {
            appVersion: version
        }
    }
}
</script>

<style scoped>
.footer a {
    text-decoration: none;
}

</style>