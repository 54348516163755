<template>
    <div class="container p-4">
        <h1 class="mb-5">grandMA2 Fixture&nbsp;Finder</h1>
        <div class="row">
            <div class="col-md-3">
                <FilterPanel 
                    :channels-count="channelsCount"
                    @changeChCount="changeChannelsCount"
                    @changeAttributes="changeAttributes"
                />
                <Footer/>
            </div>
            <div class="col-md-9">
                <div class="rounded-2 bg-secondary p-3">
                    <Message :msg="message"></Message>
            
                    <Fixture 
                        v-for="fixture in fixtures" 
                        :name="fixture.Name" 
                        :filename="fixture.Filename" 
                        :channels="fixture.Channels"
                    />
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import FilterPanel from '@/components/FilterPanel.vue'
import Message from '@/components/Message.vue'
import Fixture from '@/components/Fixture.vue'
import Footer from "@/components/Footer.vue"

export default {
  name: 'App',
  components: {
    FilterPanel,
    Message,
    Fixture,
    Footer
  },
  data() {
    return {
        message: "Select the number of channels in your fixture",
        channelsCount: 0,
        allFixtures: [],
        fixtures: [],
        choosenAttributes: []
    }
  },
  methods: {
    async changeChannelsCount(count) {
        this.channelsCount = count;
        try {
            const res = await fetch(`/data/fixture_${count}_ch.json`);
            
            if (res.ok) {
                this.allFixtures = await res.json();
                
            }
            if (!res.ok) {
                this.allFixtures = [];
                
            }
            this.filterFixtures();
            
        } catch {
            this.allFixtures = [];
            this.filterFixtures();
        }
        
    },
    changeAttributes(choosenAttributes) {
        this.choosenAttributes = choosenAttributes;
        this.filterFixtures();
    },
    getFoundCountText() {
        if (this.fixtures === undefined) return "Fixtures not found";
        let fixturesCount = this.fixtures.length;
        if (fixturesCount === 0) return "Fixtures not found";
        if (fixturesCount === 1) return "Fixture found: 1";
        if (fixturesCount > 1) return `Fixtures found: ${fixturesCount}`;
    },
    filterFixtures() {
        let filteredFixtureList = [];
        let choosenAttributes = this.choosenAttributes;
        this.allFixtures.forEach(function(fixture){
            let includeFixture = true;
            fixture.Channels.forEach(function(channel){
                if (choosenAttributes[channel.Number] === undefined) return;
                if (choosenAttributes[channel.Number] === "ANY") return;
                if (choosenAttributes[channel.Number] === channel.Attribute) return;
                includeFixture = false;
            });
            if (!includeFixture) return;
            filteredFixtureList.push(fixture)
        });
        this.fixtures = filteredFixtureList;
        this.message = this.getFoundCountText();
    }
  }
}
</script>

<style>
@font-face {
    font-family: 'Ggg';
    src: url('./fonts/ggg.ttf') format('truetype'); 
}

body {
    font-family: Ggg, "Times New Roman", Times, serif;
}

h1 {
    color: #ffb300;
    font-weight: bold;
}
</style>
